<template>
  <v-card max-height="90%">
    <v-card-title class="text-subtitle-1 text-wrap font-weight-bold mb-3">
      {{ song.title }}
    </v-card-title>
    <v-card-text>
      <v-container class="pa-0 mt-n4">
        <v-row>
          <v-col class="pa-0">
            <v-textarea
              :model-value="song.sequence"
              auto-grow
              rows="1"
              label="Sequence"
              readonly
              density="compact"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-n2 pa-0">
          <v-col class="pa-0">
            <v-textarea :model-value="song.lyrics" auto-grow label="Lyrics" readonly>
            </v-textarea> </v-col
        ></v-row>
        <v-row class="mt-n2 pa-0">
          <v-col class="d-flex justify-center">
            <v-btn @click="copyToClipboard(song)" variant="tonal"
              >Copy To Clipboard</v-btn
            >
            &nbsp;
            <v-btn @click="closeLyrics" variant="tonal">Close</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-snackbar :model-value="snackbarVisible">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarVisible = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
const _ = require("lodash");
const isUndefined = _.isUndefined;

export default {
  props: {
    currentSong: Object,
  },
  data: function() {

    return {
      snackbarText: "",
      snackbarVisible: false,
      song: {},
    }
  },
  mounted: function () {
    var self = this;
    self.song = self.currentSong;
  },
  computed: {
    sequenceLength: function () {
      if (!isUndefined(this.song.sequence)) {
        return this.song.sequence.length;
      }

      return 0;
    },
  },
  methods: {
    message: function (message) {
      this.snackbarText = message;
      this.snackbarVisible = true;
    },
    closeLyrics: function () {
      this.$emit("close");
    },
    copyToClipboard: function (song) {
      var self = this;
      var clipboardText=`${song.title}

${song.lyrics}`
      clipboardText=clipboardText.trim()
      navigator.clipboard.writeText(clipboardText).then(()=>{
        self.message("Copied to clipboard.");
      });
    },
  },
};
</script>
